import { MyLink } from 'components/Layout/Link';
import Meta from 'components/Layout/Meta';
import { sunsetImage } from 'images';
import { NextPage } from 'next';
import React from 'react';
import styled from 'styled-components';
import { withApollo } from '../apollo/apollo';

interface Props {
    userAgent?: string;
}

export const Page404 = styled.main`
    min-height: 60rem;
    background: url(${sunsetImage.src}) no-repeat;
    background-size: cover;
    background-position: 0 25%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    // color: ${({ theme }) => theme.colors.white};

    .text {
        text-align: center;
        margin: 10rem auto 0 auto;
    }

    .links {
        margin: 5rem 0;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;

        a {
            margin: 2rem;
        }
    }
`;

const FourOhFourPage: NextPage<Props> = ({}) => {
    return (
        <Page404 className="full-bleed">
            <Meta />
            <div className="text">
                <h1 className="">404</h1>
                <p className="heading-3 ">Whoops!</p>
                <p className="featured ">
                    It looks like you&apos;ve wandered into a patch of fallow land. <br />
                    There&apos;s nothing here for the moment, perhaps you&apos;d like to try one of these?
                </p>
            </div>
            <div className="links">
                <MyLink hrefProp="/shop" text="Shop" />
                <MyLink hrefProp="/journal" text="Journal" />
                <MyLink hrefProp="/dahlia-field" text="Dahlia Field" />
                <MyLink hrefProp="/contact" text="Contact Us" />
            </div>
        </Page404>
    );
};

export default withApollo(FourOhFourPage);
